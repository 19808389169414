float-full(dir=left) {
	display: block
	width 100%
	float: dir
}

float(dir=left) {
	if dir==left {
		float:left
	}
	else {
		float:right
	}
}

clear() {
	clear: both;
}

nomargin() {
	margin: 0
}

fill-parent() {
	width: 100%;
	height: 100%;
}

contentbox(){
	box-sizing: content-box
}

borderbox(){
	box-sizing: border-box
}

grayScale() {
	-webkit-filter: grayscale(1)
	filter: grayscale(1);
}

noGrayScale() {
	-webkit-filter: none
	filter: none
}

absolutely() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

animate(name,duration,delay=0,easing, noreturn, once){
	
	if noreturn == true {
		if once == true {
			-webkit-animation: name duration delay easing forwards
	        -webkit-animation-iteration-count: 1
			-o-animation:      name duration delay easing forwards
	        -o-animation-iteration-count: 1
			-moz-animation:    name duration delay easing forwards
	        -moz-animation-iteration-count: 1
			animation:         name duration delay easing forwards
	        animation-iteration-count: 1
		} else {
			-webkit-animation: name duration delay easing forwards
	        -webkit-animation-iteration-count: infinite
			-moz-animation:    name duration delay easing forwards
	        -moz-animation-iteration-count: infinite
			-o-animation:      name duration delay easing forwards
	        -o-animation-iteration-count: infinite
			animation:         name duration delay easing forwards
	        animation-iteration-count: infinite
		}
	} else {
		if once == true {
			-webkit-animation: name duration delay easing
	        -webkit-animation-iteration-count: 1
			-moz-animation:    name duration delay easing
	        -moz-animation-iteration-count: 1
			-o-animation:      name duration delay easing
	        -o-animation-iteration-count: 1
			animation:         name duration delay easing
	        animation-iteration-count: 1
			
		} else {
			-webkit-animation: name duration delay easing
	        -webkit-animation-iteration-count: infinite
			-moz-animation:    name duration delay easing
	        -moz-animation-iteration-count: infinite
			-o-animation:      name duration delay easing
	        -o-animation-iteration-count: infinite
			animation:         name duration delay easing
	        animation-iteration-count: infinite
		}
	}
}


breakpoint(class) {
	if class == xxs {
    	@media (max-width: 500px) {  block }
  	
  	} 
  	if class == xs {
		@media (max-width: 767px) {  block }
	
	} 
	if class == sm {

		@media (min-width: 768px) {  block }
	}
	if class == md {

		@media (min-width: 992px) {  block }
	}
	
	if class == lg {

		@media (min-width: 1200px) {  block }
	
	}

}