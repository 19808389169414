@import  "variables.styl";
@import  "animations.styl";
@import  "mixins.styl";
// @import  url(breakpoints.styl);

* {
	borderbox()
}

html {
	font-size: 12px
}

body {
	font-family: $sourceSans
	background: $lightGrey
	padding: 0
	nomargin()
	float-full()
}

ul {
	list-style: none
	padding: 0
	nomargin()
}

header {
	background: $white
	float-full()
}

a {
	text-decoration: none
}

nav {
	
	text-align: center
    padding-bottom: 1rem
	
	& a {
		
		font-family: $raleway
		padding: 1rem 0.25rem
		color: $darkGrey
        display: inline-block
		
		&:hover {
			background: $lightGrey;
		}
		
		&.selected {
			color: $orange
		}
		
	}
	
	& a + a {
		margin-left: 1rem
	}
	
}

h1, h2, h3 {
	font-family: $sourceSans
	font-weight: 200
	nomargin()
}

h1 {
	color: $darkGrey
	font-size: 3rem
    line-height: 2.5rem;
    margin-bottom: 1rem
	float-full()
}

h2 {
	color: $white
	background: $green
	padding: 0.5rem 2rem 
	font-size: 2rem
	float()
}

h3 {
    font-weight: 500
	font-size: 1.5rem
	color: $green
	float-full()
}


.clearfix {
	clear both
}

.logo {
	position: relative
	height: $logoSize
}

.logo-svg {
	
	width: $logoSize
    left: 0
    right: 0
    margin: auto
    position: absolute
    
    & #rotor {
    	animate(loading,1s,0,linear,true, false)
        transform-origin: 50%
    }
    
}

#About {
	& .interests {
		margin-top 2rem
		float-full()
	}
}

.bio {
	
	float()
	
	.bio-pic, .bio-location {
		width: 22%
		float()
	}
	
	.bio-pic {
		
	padding-right: 50px;

		+breakpoint(xxs) {
		    width: 100%;
		    padding: 0;
		    float: none;
		}		
			
		+breakpoint(xs) {
		    width: 60%;
		    padding: 0;
		    float: none;
		    margin: 0 20%;
		}x
		
	}
	
	.bio-location {
		+breakpoint(xs) {
			display: none;
		}
	}
	
	.bio-content {
		width: 56%
		float()
		
		+breakpoint(xs) {
		    width: 100%;
		    margin-top: 2rem;
		}
		
		blockquote {
	        margin: 0;
		}
	}
	
}


.skills-list {
	
	clear()
	float-full()
	padding-top: 2rem
	
	& li {
		
		display: block
		overflow: hidden
		width: 8%
		background-repeat: no-repeat
		background-size: cover
		cursor: pointer
		float()

		+breakpoint(xs) {
			width: 18%;
		}
		
		
		&#Javascript {
			padding: 1% 0	
		}
		
		&#jQuery {
			width: 25%
			padding: 1.25% 0
			margin-left: 1%

			+breakpoint(xs) {
				width: 50%;
				margin-left: 2%
			}

		}
		
		&#Stylus {
			width: 17%
			margin-left: 1%
			
			+breakpoint(xs) {
				width: 34%;
				margin-left: 2%
			}
		}
				
		&#Sass {
			width: 14%
			margin-left: 4%
			
			+breakpoint(xs) {
				width: 28%;
				margin-left: 0;
			}
		}
		
		
		&#Illustrator,
		&#Sketch,
		&#Photoshop {
	        width: 10%
	        padding: 2% 1%
	        margin-left: 2%
	        
			+breakpoint(xs) {
				width: 20%;
				margin-left: 4%
			}
		}
		
		&#Sketch {
			margin-left: 0;
		}
		
		& + li {
			margin-left: 3%
		}
		
		&:hover {
			animate( hover-anim-small, 500ms, 0, ease-in, true, true )
			// background: rgba(255, 255, 255, 0.52)
			border-radius: 3px
		}
		
		&:hover:after {
			display: block
		}

		
	}
	
} 


#More .inner-container{
	
	background $white
	padding 3rem
	
	.interests {
		
		float-full()
		
		& svg {
			
			display:inline-block
			width: 18%
			
			&:first-of-type {
				clear: both
			}
		}
		
		& svg + svg {
			margin-left: 2%;
		}
	}
}



section {
	
	float-full()
	
	+breakpoint(xs) {
		padding: 0 2rem;
	}	
	
	+breakpoint(sm) {
		padding: 0 2rem;
	}
	+breakpoint(lg) {
		padding: 0;
	}

	&:first-child {
		
		& .inner-container {
			background: $white
	        padding: 3rem
		}
	}

	&.what-i-do {
		
		background: $green 
		min-height: 500px
		position: relative
		
		& canvas {
	        position: absolute
	        top: 0
	        left: 0
		}
		
		& .responsive {
	    	width 60%;
		}

		& .interface {
	    	width 40%;
		}

		& .deving,
		& .interaction {
			width: 50%;
		}
		
		& .what-i-do-left,
		& .what-i-do-right {
			width: 50%;
	    	float: left;
	    	
			+breakpoint(xs) {
		    	width: 100%;
			}	
			
		}

		& .what-i-do-heading {
			
			display: inline-block
			opacity: 0
			padding: 1rem 0
			color: $white 
			text-align center
			margin-top: 2rem;
			
			&.responsive {
				transition: opacity 100ms 1s ease;
			}
			&.interface {
				transition: opacity 100ms $animTwoWait ease;
			}
			&.deving {
				transition: opacity 100ms $animThreeWait ease;
			}
			&.interaction {
				transition: opacity 100ms $animFourWait ease;
			}
			& span {
				padding: 1rem
				border solid thin $white
				background $green;
			}
		}
		
		& .inner-container {
			padding: 3rem
			border: solid thin $white
		}
		
		& h2 {
			background: $white
			color: $green
		}			
		
		& .container-box {
			overflow hidden
		    height 200px
		    float()
		    
		    &.responsive {
		    	text-align center
		    }
		    
		    &.interface {
		    	text-align center
		    	padding-top 3rem
		    }
		    &.deving {
				transition: opacity 100ms $animThreeWait ease
		    	padding-top: 3rem;
			}
			
		}
			
		& .container-box:first-of-type {
				clear: both
		}
		
	}
	
	& .inner-container {
        position: relative
        z-index: 200
		overflow: hidden		
	    margin: 5% auto
	}	
	
	+breakpoint(md) {
		
		& .inner-container {
			width: 100%
		}
		
	}
	
	+breakpoint(lg) {
		
		& .inner-container {
			min-width: 865px
			width: 60%;
		}
		
	}

	&#Contact {
		background $darkGrey;
		& .inner-container {
			overflow: visible;
		}
		
	}
	
}

.split-sm {
	
	+breakpoint(xs) {
		clear: both;
	}

}


.heading-container {
	padding-bottom: 1rem
	display: block
	float: left
	width: 100%;
}

.heading-anim-container {
	height 11rem;
}

 .heading-anim {
	width 10px
	height 0rem
	overflow hidden
}



.responsive {
	
	opacity: 0;
	transition: opacity 200ms 1s ease;

	& .screen-outer {
		width: 55px;
		height: 100px;
		background: $white;
		border-radius: 6px;
		padding: 9px 4px;
		position: relative;	
		transform-origin: 87px 121px;
		box-sizing: content-box;
	}

	& .speaker {
		background: $mediumGrey;
		width: 16px;
		height: 2px;
		position: absolute;
		top: 4px;
		left:0;
		right:0;
		margin: auto;
	}

	& .screen-inner {
		background: $lightGrey;
	  	overflow: hidden;
		fill-parent()
	}

	& .button {
		position: absolute
		left:0
		right:0
		width: 5px
		height: 5px
		background: $mediumGrey
		border-radius: 100%;
		bottom: 3px
		margin: auto;
	}

	& .laptop {
		
		display: block
		// float()
		position: relative
		width: 240px
		height: 12px
		background: $white
	    border-radius: 0 0 10px 10px
	    margin-top: 150px

		&:before {
			content: '';
			width: 45px;
			height: 5px;
			background: $mediumGrey;
			top:0;
			left:0;
			right:0;
			margin: auto;
		    display: block;
		    border-radius: 0 0 5px 5px;
		}

	}

	& .content {
		width:100%;
		height: 200%;
		transition: margin 500ms 1.5s ease;
		position: relative;
	}

	& .content-item {
		width: 100%;
		height: 33.33%;
		background: $darkGrey;
	}

	& .content-item:nth-child(2) > span {
		background: $green;
		width: 50%;
		height: 50%;
		display: block;
		float: left;
		transition: margin 300ms 3s ease-out, width 300ms 3s ease-out, height 300ms 3s ease-out;
	}
	
	& .top-menu-half {
		color: $green
		width: 100%
		height: 45%
		margin-left: -100px
		display: block
		transition: margin 500ms 2s ease-out
	    font-size: 34px
	    font-weight: bold
	}
	& .device-container {
	    overflow: hidden
	    position: relative
	    display: inline-block
	    transform: scale(0.7);
	}
	
}

.spacer {
	height: 3.5rem;
	line-height: 3.5rem;

}

.interface-blocks {
	
    margin: auto
    overflow: hidden
    display: inline-block
    margin-left: -5px
    // width: 105px
    
	& > li {
		
		display: block
		float: left
		width: 1px
		height: 1px
		width: 1px
		height: 1px
		border: 0 solid rgba(0,0,0,0)
		position: relative
		contentbox()
		
		&:first-child { 
			z-index: 30
		}
		&:nth-child(2) { 
			z-index: 20
		}		
		&:nth-child(3) { 
			z-index: 10
		}
		&:nth-child(3n+4) { 
			clear: both
		}
		
	}
}

.interaction {
	
	position: relative;

	& button {
		cursor: pointer
		absolutely()
		display: block
		z-index: 0
		border: none
		background: $darkGrey
		color: $white
		text-transform: uppercase
	    padding: 10px 20px
	    font-size: 15px
	    width: 102px
		height: 37px
		opacity: 0;
		transform: translate3d(0, 0, 0)
		transition: opacity 100ms $animFourWait ease;

		&:focus {
			outline: none
		}
		img {
			display: none
		}
		
	}
	
}

.if-statement { 

	padding-left: 1rem;

	& > li {
		
		font-family: $mono
		display: inline-block
		width: 0
		height: 1rem
		color: $white
		font-weight: 400
		margin-bottom: 1rem
		overflow: hidden
		background $green

		&:nth-child(1) {
			transition: width 700ms $animThreeWait linear;
		}

		&:nth-child(2) {
			transition: width 700ms ($animThreeWait + 950ms) linear;
		}

		&:nth-child(3) {
			transition: width 700ms ($animThreeWait + 1700ms) linear;
		}

		&:nth-child(4) {
			transition: width 700ms ($animThreeWait + 2450ms) linear;
		}

		&:nth-child(5) {
			transition: width 700ms ($animThreeWait + 3200ms) linear;
		}
	
		& .orange {
			color: #F3C53D;
		}

		& .pink {
			color: #D64057;
		}

		& .indented {
			padding-left: 2rem;
		}
	
	}

}

.start {

	& .heading-anim {
		animate(heading-anim, 1.5s, 0s, linear, true, true)
	}

	& .responsive {
	
		opacity 1

		& .screen-outer {
			transition: width 500ms $animOneWait $cbOne, height 500ms $animOneWait $cbOne, border-radius 500ms $animOneWait $cbOne, padding 500ms $animOneWait $cbOne;
			width: 110px
			height: 160px
			border-radius: 10px
			padding: 9px 8px
			animation: turn-to-tablet 500ms ($animOneWait+0.5s) $cbOne forwards, bounce 1s ($animOneWait+1.7s) ease forwards
		}

		& .content {
			margin-top: -104px
		}
		
		& .laptop {
			animate(turn-to-laptop, 750ms, ($animOneWait+1.25s)s, $cbTwo, true, true)
		}

		& .content-item:nth-child(2) > span {
			width: 47%
			height: 48%
		    width: 47%;
		    margin-left: 2%;
		    
			&:nth-child(1n+3) {
				margin-top: 2%
			}
		}
			
		& .top-menu-half  {
			margin-left: 0px;
		}

	}
	
	& .interface {

		& .interface-blocks > li {

			&:first-child {
				animate(bubble-first-child, 400ms, ($animTwoWait + 0s), $cbThree, true, true)
			}

			&:nth-child(2) {
				animate(bubble-no-top, 400ms, ($animTwoWait + 50ms), $cbThree, true, true)
			}


			&:nth-child(3){
				animate(bubble-no-top, 400ms, ($animTwoWait + 100ms), $cbThree, true, true)
			}

			&:nth-child(4) {
				animate(bubble-with-top, 400ms, ($animTwoWait + 50ms), $cbThree, true, true)
				margin-left:0;
			}

			&:nth-child(5),
			&:nth-child(7) {
				animate(bubble-with-top, 400ms, ($animTwoWait + 100ms), $cbThree, true, true)
			}

			&:nth-child(6),
			&:nth-child(8) {
				animate(bubble-with-top, 400ms, ($animTwoWait + 150ms), $cbThree, true, true)
			}

			&:last-child {
				animate(bubble-with-top, 400ms, ($animTwoWait + 200ms), $cbThree, true, true)
			}

			&:nth-child(2),
			&:nth-child(3) {
				transition: 	border 200ms 1s ease, margin 200ms 1s ease
			}

		}
		
	}
	
	& .interaction button {
		opacity: 1;
		
		&:hover:not(.clicked) {
			animate(hover-anim, 500ms, 0, ease-in, true, true );
		} 
		&.clicked  {
			animate(click-anim, 500ms, 0, ease-in, true, true );
			img {
			    display: inline-block;
				absolutely()
			}
		}
	}
	
	& .deving {
		
		& .if-statement > li {
			width 100%
		}

	}


	&.what-i-do {
		
		& .what-i-do-heading {
			
			opacity: 1
			
		}
	}

}

		
.twitter-timeline {
	width: 55% !important;

	+breakpoint(xs) {
		display: none !important;
	}
}

a.twitter-timeline {
	color: $darkGrey;
}



.contact-form {
	width: 45%;
	vertical-align: top;
	display: inline-block;
	padding: 0 1.5rem 0 0;
	
	+breakpoint(xs) {
		width: 100%;
		padding: 0;
		margin-bottom: 2rem;
	}
	
	& input, 
	& textarea {
		display: block;
		width: 100%;
		padding 1rem;
		background: $white;
		border-radius: 3px;
		border: solid thin $lightGrey;
		margin-bottom: 1rem;
	}
	& button {
		width: 100%
		background: $green
		padding: 2rem
		border-radius: 3px
		border: none
		color: $white
		font-size: 2rem;
	    font-weight: 100;
	    letter-spacing: 2px;
		margin-top: 1rem;
	    cursor: pointer;
		
		&:hover {
			animate( hover-anim-subtle, 500ms, 0, ease-in, true, true )
		}	
		
	    
	    img {
	    	display: none;
	    }
	    
	    &.loading img {
		    display: inline-block;
    	    position: relative;
		    margin: auto;
		    max-height: 2rem;
		    max-width: 2rem;
		    width: 100%;
			animate( spin, 1500ms, 300ms, ease, true, false );
	    }
	    
	    &.sent {
	    	background: $lightGrey;
	    }
	    
	    &.oops {
	    	background: $orange;
	    }
	}
}



for break in lg md sm xs xxs {
 	
    .show-{break} {
		
		+breakpoint(break) {
	    	display: block !important;
		}	
		
    }

    .hide-{break} {
		
		+breakpoint(break) {
	    	display: none !important;
		}
		
	}
    
}


// .examples-list {
	
// 	float-full()
	
// 	 & li {
// 		display: inline-block
// 		width: 30%;
// 		margin-bottom: 3rem
// 		float()
		
		
// 		& + li {
// 			margin-left: 5%
// 		}
		
// 		&:nth-child(3n+4) {
// 			margin-left: 0
// 		}
		
// 		& figure {
// 			width: 100%
// 			background: $darkGrey
// 			nomargin()
			
// 			& img {
// 				width: 100%
// 				grayScale();
				
// 				&:hover {
// 					noGrayScale()
// 				}
// 			}
// 		}
		
// 		& figcaption {
// 			width: 100%
// 			height: 100px
// 			background: $white
// 			nomargin()
			
// 			& h4 {
// 				nomargin()
// 			}
// 		}
// 	}
// }




















