
@keyframes turn-to-tablet {
  	0% {
   		transform: rotate(0deg)
  	}
 	100% {
  	 	transform: rotate(90deg)
  	}
}

@keyframes turn-to-laptop {
  	0% {
   		margin-top: 100px
  	}
 	100% {
  	 	margin-top: -17px
  	}
}

@keyframes bounce {
  	0% {
   		margin-top: 0px
  	 	width: 110px
  	}
 	60% {
  	 	margin-top: -60px
  	 	width: 90px
  	 	margin-bottom: 60px
  	}

  	80% {
  	 	margin-top: 50px
  	 	width: 60px
  	 	margin-bottom: -50px
  	}

  	88% {
	 	margin-top: -10px
  	 	width: 100px
  	 	margin-bottom: 10px
  	}

 	100% {
	  	margin-top: 0
  	 	width: 110px
  	}
}


@keyframes bubble-with-top {
	0% {
		width: 1px
		height: 1px
		border-left: solid 20px $green
		border-top: solid 20px $green
		background: $green
	}
	100%{
		width: 30px
		height: 30px
		border-left: solid 5px $green
		border-top: solid 5px $green
		background: $white
	}
}

@keyframes bubble-no-top {
	0% {
		width: 1px
		height: 1px
		border-left: solid 20px $green
		background: $green
	}
	100%{
		width: 30px
		height: 30px
		border-left: solid 5px $white
		background: $white
	}
}

@keyframes bubble-first-child {
	0% {
		width: 1px
		height: 1px
		border-left: solid 20px $green
		background: $green
	}
	100% {
		width: 30px
		height: 30px
		border-left: solid 5px $green
		background: $white
	}
}

@keyframes spin {
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(720deg);
	}
}

@keyframes hover-anim {
	0% {
	}

	25% {
		transform: scale(1.2)
	}

	40% {
		transform: scale(1.8)
	}

	60% {
		transform: scale(1.4)
	}

	80% {
		transform: scale(1.6)
	}

	100% {
		transform: scale(1.5)
	}
}



@keyframes hover-anim-small {
	0% {
	}

	25% {
		transform: scale(1.1)
	}

	40% {
		transform: scale(1.4)
	}

	60% {
		transform: scale(1.25)
	}

	80% {
		transform: scale(1.4)
	}

	100% {
		transform: scale(1.3)
	}
}

@keyframes hover-anim-subtle {
	0% {
	}

	25% {
		transform: scale(1.05)
	}

	40% {
		transform: scale(1.2)
	}

	60% {
		transform: scale(1.125)
	}

	80% {
		transform: scale(1.2)
	}

	100% {
		transform: scale(1.15)
	}
}


@keyframes loading {
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
}

@keyframes heading-anim {
	20% {
		width: 1%
		height: 3.5rem
	}
	
	100% {
		width: 100%
		height: 3.5rem
	}
}


@keyframes click-anim {
	0% {
		transform: scale(2)
		border-radius: 0
        font-size: 1px
	}

	25% {
		transform: scale(0.8)
		color: rgba(255,255,255,0)
        width: 50px
		height: 50px
		border-radius: 25px
	}


	40% {
		transform: scale(0.8)
		color: rgba(255,255,255,0)
        width: 50px
		height: 50px
		border-radius: 25px
	}


	60% {
		transform: scale(1.8)
		color: rgba(255,255,255,0)
	 	width: 80px
		height: 80px
		border-radius: 100%
	}

	80% {
		transform: scale(1.4)
	}

	90% {
		transform: scale(1.7)
	}

	100% {
		transform: scale(1.5)
		color: rgba(255,255,255,0)
	 	width: 80px
		height: 80px
		border-radius: 100%
	}
}

